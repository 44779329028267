import "./App.css";
import LandingPage from "./components/pages/LandingPage/LandingPage";

export const App = () => {
  return (
    <header className="container">
      <LandingPage />
    </header>
  );
};
