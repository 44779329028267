import "./LandingPage.css";
import React from "react";

const LandingPage = () => {
  return (
    <section className="container-landing-page">
      <div className="container-heading-primary">
        <p className="heading-primary--main">Hot Fudge</p>
        <div className="container-footer">
          <p className="heading-primary--sub">W.I.P.</p>
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
